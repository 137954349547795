@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown-body ol li {
  @apply list-decimal;
}

.markdown-body ul li {
  @apply list-disc;
}

.markdown p {
  @apply text-gray-700 mb-6;
}

.markdown h1 {
  @apply text-3xl font-bold mb-6;
}

.markdown h2 {
  @apply text-2xl font-bold mb-6;
}

.markdown h3 {
  @apply text-xl font-bold mb-6;
}

.markdown h4 {
  @apply text-lg font-bold mb-6;
}

.markdown h5 {
  @apply font-bold mb-6;
}

.markdown h6 {
  @apply text-sm font-bold mb-6;
}

.markdown a[href] {
  @apply text-blue-500;
}
